import React from 'react';
import PropTypes from 'prop-types';

import getHighlightNews from 'libs/api/Home';
import { getYoutubeData, getMenus } from 'libs/api';
import VARIAVEIS_GLOBAIS from 'theme/utils/globalVariables';
import { getBetLives, getMatchHighlights } from 'libs/api/Match';
import {
  verifyUserAgent,
  extractContentOfTagTitle,
} from 'theme/utils/functions';

import Home from 'components/pages/Home';
import Box from 'components/foundation/layout/Box';
import BetPageHOC from 'components/wrapper/BetPage/hoc';

const HomePage = ({
  agent,
  isMobile,
  liveEvents,
  highlightNews,
  youtubeShorts,
  matchsHighlights,
} = {}) => (
  <Box
    as='main'
    $cssWidth='100%'
    data-agent={agent}
  >
    <Home
      isMobile={isMobile}
      liveEvents={liveEvents}
      youtubeShorts={youtubeShorts}
      highlightNews={highlightNews}
      matchsHighlights={matchsHighlights}
    />
  </Box>
);

HomePage.propTypes = {
  // Returns the user's User-Agent
  agent: PropTypes.string,
  // Returns whether the request is from a mobile device
  isMobile: PropTypes.bool,
  // Returns the games with live odds
  liveEvents: PropTypes.shape({}),
  // Array with the featured news
  highlightNews: PropTypes.shape({}).isRequired,
  youtubeShorts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  matchsHighlights: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // Returns the games with on Home Betnacional
  matchsHome: PropTypes.shape({
    oddsWord: PropTypes.shape({}),
    oddsBrazil: PropTypes.shape({}),
    odds: PropTypes.shape({}).isRequired,
    live_count: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export async function getServerSideProps({ req, res }) {
  // Setting the cache on the server side
  res.setHeader(
    'Cache-Control',
    `public, max-age=${VARIAVEIS_GLOBAIS.CACHE.HOME}`
  );
  const agent = req.headers['user-agent'];
  const isMobile = verifyUserAgent({ userAgent: agent });

  const customHeaders = {
    'User-Agent': `${agent} Nextjs/SSR`,
  };

  const dataMenus = await getMenus({ ssr: true, customHeaders });

  const highlightNews = await getHighlightNews({ ssr: true, customHeaders });

  const dataYoutubeShorts = await getYoutubeData({
    ssr: true,
    customHeaders,
    params: 'shorts',
  });

  const liveEvents = await getBetLives({ ssr: true, customHeaders });

  const matchsHighlights = await getMatchHighlights({
    ssr: true,
    customHeaders,
  });

  return {
    props: {
      agent,
      isMobile,
      liveEvents,
      matchsHighlights,
      highlightNews: highlightNews || {},
      youtubeShorts: dataYoutubeShorts?.videos || [],
      pageWrapperProps: {
        customLogoTag: 'h1',
        seoProps: {
          description: null,
          seo: highlightNews?.seo || '',
          title: extractContentOfTagTitle({ src: highlightNews?.seo?.head }),
        },
        menuProps: {
          display: true,
          menus: dataMenus?.menu || {},
        },
      },
    },
  };
}

export default BetPageHOC(React.memo(HomePage), {
  pageWrapperProps: {
    seoProps: {},
    pageBoxProps: {
      className: 'home',
    },
  },
});
