import styled, { css } from 'styled-components';
import breakpointsMedia from 'theme/utils/breakpointsMedia';

export const ArticleGridWrapper = styled.div``;

export const Row = styled.div`
  ${({ theme }) => css`
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: ${theme.spacings.sm};

    ${breakpointsMedia({
      lg: css`
        overflow-x: visible;
        gap: ${theme.spacings.md};
      `,
    })}
  `}
`;

export const Col = styled.div`
  ${({ limit }) => css`
    min-width: 33rem;

    ${breakpointsMedia({
      lg: css`
        min-width: auto;
        width: calc(100% / ${limit});
      `,
    })}
  `}
`;
