import styled, { css } from 'styled-components';
import breakpointsMedia from 'theme/utils/breakpointsMedia';

const HomeArticleHighlightWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.xl};
    margin: ${theme.spacings.md} 0 0;

    ${breakpointsMedia({
      md: css`
        display: grid;
        gap: ${theme.spacings.xxl};
        margin: ${theme.spacings.xxxl} 0;
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
          'HighlightPrimary HighlightPrimary HighlightSecondary HighlightSidebar'
          'HighlightPrimary HighlightPrimary HighlightSecondary HighlightSidebar'
          'HighlightAds HighlightAds HighlightAds HighlightSidebar';
      `,
    })}

    .highlight__primary {
      ${breakpointsMedia({
        md: css`
          grid-area: HighlightPrimary;
        `,
      })}
    }

    .highlight__secondary {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacings.md};

      ${breakpointsMedia({
        md: css`
          gap: ${theme.spacings.xl};
          grid-area: HighlightSecondary;
        `,
      })}
    }

    .highlight__sidebar {
      ${breakpointsMedia({
        md: css`
          overflow: hidden;
          grid-area: HighlightSidebar;
        `,
      })}

      .highlight__shorts {
        margin-left: -${theme.spacings.sm};
        width: calc(100% + ${theme.spacings.md});

        ${breakpointsMedia({
          md: css`
            width: 100%;
            margin-left: 0;
          `,
        })}
      }
    }

    .highlight__liveOdds {
      margin-bottom: 24px;

      ${breakpointsMedia({
        md: css`
          margin-bottom: 8px;
        `,
      })}
    }

    .highlight__ads {
      ${breakpointsMedia({
        md: css`
          grid-area: HighlightAds;
        `,
      })}
    }
  `}
`;

export default HomeArticleHighlightWrapper;
