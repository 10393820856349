import styled, { css } from 'styled-components';
import { TextStyleVariants } from 'components/foundation/Text';

export const FeaturedEventsWrapper = styled.div`
  padding-top: 20px;

  ${({ theme }) => css`
    .featured-events__slider {
      .featured-events__slider-item {
        width: auto;

        .head-to-head__wrapper {
          width: 32rem;
          height: 12rem;
          border-radius: ${theme.borderRadius};
          border: 0.5px solid ${theme.colors.base['100']};
          background: url('/images/temp/confrontation-facts/card-bg.png')
            lightgray 50% / cover no-repeat;
        }
      }
    }
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Filter = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacings.sm};

    button {
      border: 0;
      height: 2.4rem;
      cursor: pointer;
      border-radius: 10rem;
      background: transparent;
      padding: 0 ${theme.spacings.md};
      border: 1px solid ${theme.colors.base['300']};

      &.active {
        border: 2px solid ${theme.colors.base['900']};
      }
    }

    select {
      border-radius: 3.2rem;
      color: ${theme.colors.base['900']};
      border: 0.5px solid ${theme.colors.base['300']};
      padding: ${theme.spacings.xs} ${theme.spacings.sm};
      ${TextStyleVariants({
        $variant: { typography: 'bodyTextXXXSmall' },
      })};
    }
  `}
`;

export default FeaturedEventsWrapper;
