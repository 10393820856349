import React from 'react';
import PropTypes from 'prop-types';

import Article from 'components/common/Article';
import Grid from 'components/foundation/layout/Grid';
import SectionTitle from 'components/common/SectionTitle';

import 'react-loading-skeleton/dist/skeleton.css';
import * as S from './styles';

const ArticleGrid = ({ limit, articles, articleProps, sectionTitleProps }) => (
  <S.ArticleGridWrapper>
    <SectionTitle {...sectionTitleProps} />

    <Grid.Container
      $noGutter={{
        xs: true,
        md: true,
      }}
    >
      <S.Row>
        {articles?.map((article, index) =>
          index < limit ? (
            <S.Col
              key={`article-${article?.noticia?.uri || article?.uri || index}`}
              limit={limit}
            >
              <Article
                data={article?.noticia || article}
                {...articleProps}
              />
            </S.Col>
          ) : null
        )}
      </S.Row>
    </Grid.Container>
  </S.ArticleGridWrapper>
);

ArticleGrid.propTypes = {
  limit: PropTypes.number.isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  articleProps: PropTypes.shape({}).isRequired,
  sectionTitleProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    viewMore: PropTypes.shape({
      link: PropTypes.string,
    }),
  }).isRequired,
};

export default React.memo(ArticleGrid);
