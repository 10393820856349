import React, {
  useRef,
  useMemo,
  useState,
  useLayoutEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';

import { isEmpty, size } from 'theme/utils/functions';
import { getMatchHighlights } from 'libs/api/Match';

import Text from 'components/foundation/Text';

import FeaturedEventsCard from './FeaturedEventsCard';

import * as S from './styles';
import 'swiper/swiper-bundle.css';

const INITIAL_STATE = {
  visibleCards: 10,
  matches: [],
};

const MATCH_SOCCER_TYPE = 'soccer';
const REFRESH_INTERVAL_MS =
  Number(process.env.REACT_APP_FEATURED_EVENTS_REFRESH_INTERVAL) || 10000;

const FeaturedEvents = ({ data }) => {
  const intervalId = useRef(null);
  const dataOnlySoccer = useMemo(
    () => data?.filter(match => match.type === MATCH_SOCCER_TYPE),
    [data]
  );
  const blockDoubleRequests = useRef(false);
  const [stateLocal, setStateLocal] = useState({
    ...INITIAL_STATE,
    matches: dataOnlySoccer || INITIAL_STATE.matches,
  });

  const { matches, visibleCards } = stateLocal;

  const getMatchesData = useCallback(async () => {
    const fetchData = async () => {
      try {
        const matchesHighlights = await getMatchHighlights();
        const matchesOnlySoccer = matchesHighlights.filter(
          match => match.type === MATCH_SOCCER_TYPE
        );

        setStateLocal(state => ({
          ...state,
          matches: matchesOnlySoccer,
        }));
      } catch (error) {
        console.error(
          'Erro ao retornar os dados do jogos em destaque: URL /match/highlights',
          error
        );
        clearInterval(intervalId.current);
      }
    };

    intervalId.current = setInterval(async () => {
      await fetchData();
    }, REFRESH_INTERVAL_MS);

    await fetchData();
  }, []);

  const handleSwiperInteraction = useCallback(() => {
    setStateLocal(state => {
      const newVisibleCards = state.visibleCards + INITIAL_STATE.visibleCards;

      return {
        ...state,
        visibleCards:
          newVisibleCards > size(state.matches)
            ? size(state.matches)
            : newVisibleCards,
      };
    });
  }, []);

  useLayoutEffect(() => {
    if (!blockDoubleRequests.current) {
      blockDoubleRequests.current = true;
      getMatchesData();
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [getMatchesData]);

  return (
    <S.FeaturedEventsWrapper>
      <S.Header>
        <Text
          as='h4'
          $variant={{
            typography: 'heading4',
          }}
          $csscolor='600'
        >
          Eventos em destaque
        </Text>
      </S.Header>

      <Swiper
        grabCursor
        speed={500}
        spaceBetween={4}
        slidesPerView='auto'
        className='featured-events__slider'
        onSlideChange={handleSwiperInteraction}
      >
        {matches?.slice(0, visibleCards).map((match, index) =>
          isEmpty(match) ? (
            ''
          ) : (
            <SwiperSlide
              key={`featuredEvents-match-${match?.id}-${index}`}
              className='featured-events__slider-item'
            >
              <FeaturedEventsCard data={match} />
            </SwiperSlide>
          )
        )}
      </Swiper>
    </S.FeaturedEventsWrapper>
  );
};

FeaturedEvents.propTypes = {
  isMobile: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default React.memo(FeaturedEvents);
