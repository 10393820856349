import React from 'react';
import PropTypes from 'prop-types';

import ArticleGrid from 'components/common/ArticleGrid';

const DynamicCategoriesList = ({ data }) => (
  <>
    {data?.map(item => {
      const categoryInfo = item?.categoria;

      return (
        <ArticleGrid
          limit={4}
          key={categoryInfo?.link}
          articles={categoryInfo?.news}
          articleProps={{
            thumbnailSize: 'small',
            titleTypography: 'heading3',
          }}
          sectionTitleProps={{
            title: categoryInfo?.nome,
            viewMore: {
              link: categoryInfo?.link,
            },
          }}
        />
      );
    })}
  </>
);

DynamicCategoriesList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      categoria: PropTypes.shape({
        link: PropTypes.string,
        nome: PropTypes.string,
        news: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            noticia: PropTypes.object,
            thumbnail: PropTypes.string,
          })
        ),
      }),
    })
  ).isRequired,
};

export default React.memo(DynamicCategoriesList);
